<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>사용할 수 없는 채널입니다.</h3>
            <div class="single-file-check">
                <p @click="$emit('closeAlert')">확인</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'inaccessibleAlert'
};
</script>

<style scoped>

</style>
